<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import store from '@/store'
import templatesModule from '@/store/settings/fulfillment/templates'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'Templates',
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-templates')
  },
  setup() {
    const MODULE_NAME = 'templates'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, templatesModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },

}
</script>

<style>

</style>
