import axios from '@/libs/axios'

const endpoint = 'settings/fulfillment/templates'

const get = () => axios.get(`/${endpoint}`)
const update = (ctx, data) => axios.post(`/${endpoint}`, data)

export default {
  get,
  update,
}
